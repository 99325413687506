body {
  background-color: #eaeaea;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    #f72585 0%,
    #7209b7 69%,
    #3a0ca3 89%
  );
}

.ui.mini.steps {
  background: linear-gradient(135deg, #f72585 0%, #7209b7 69%, #3a0ca3 89%);
}

.react-datepicker-wrapper {
  width: 100%;
}
.pushable:not(body).patch {
  -webkit-transform: unset;
  transform: unset;
}
.pushable:not(body) .patch.ui.sidebar {
  position: fixed;
}
.ui.basic.segment.patch {
  background-color: #eaeaea;
}

.indent {
  margin-left: 1em;
}

.no-padding {
  padding: 0px !important;
  margin: 0px !important;
}

.ui.accordion {
  margin-top: 70px;
}

.ui.accordion .title:not(.ui) {
  padding: 0.2em;
}
.ui.labeled.icon.menu {
  text-align: left;
}

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    #f72585 0%,
    #7209b7 69%,
    #3a0ca3 89%
  ) !important ;
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-calendar {
  width: 100%;
  border: none;
}

.react-calendar__tile--now {
  background: rgb(85, 187, 187);
}
